import Lnd20p3 from "../../../pages/lnd20p3";
import Lnd20Profile3 from "../../../pages/lnd20Profile3";

import * as ROUTES from '../../../constants/routes';

export function isTrafficLandingName() {
    return ROUTES.ROUTE_LIST_TRAFFIC.some(item => window?.location?.href?.includes(item)) ?? false;
}

export function getLandingName() {
    return ROUTES.ROUTE_LIST.find(item => window?.location?.href?.includes(item)) ?? ROUTES.LND20;
}

export function getParentLandingName() {
    return Object
        .keys(ROUTES.ROUTE_SOURCE_MAP)
        .find(sourceMapItem => ROUTES.ROUTE_SOURCE_MAP[sourceMapItem]?.find(routeItem => routeItem === getLandingName()));
}

export function getLandingPageComponent() {
    switch (getParentLandingName()) {
        case ROUTES.LND20P3:
            return Lnd20p3;
        case ROUTES.LND20_PROFILE3:
            return Lnd20Profile3;
        default:
            return Lnd20p3;
    }
}