import React, {useState} from 'react';
import Form from './Form';
import TermModal from '../../common/Modals/TermsModal';
// import SITE from '../../../config/site';
import useInitTerm from "../../../hooks/useInitTerm";
import * as DATE from '../../../constants/date';
import styles from '../../../styles/lnd16v2/index.module.scss';

export default (props) => {
    const [cookiesBannerActive, setCookiesBannerActive] = useState(true);
    const initTerm = useInitTerm();

    return (
        <div id="middle" className={styles['middle-box']}>
            <div className={styles['middle-box__wrapp-middle']}>
                <div className={styles['banner-box']}>
                    <Form {...props} openTermModal={initTerm.openTermModal}/>
                </div>
            </div>
            <div className={styles['carousel-bg-wrapp']} style={{backgroundImage: `url(${props?.backgroundImage})`}}/>
            <div className={`${styles['banner-cookie']} ${cookiesBannerActive ? styles['open-popup_opened'] : ''} ${styles['open-popup-cookies']}`}>
                <span className={styles['banner-cookie__close']} aria-label="close" onClick={() => setCookiesBannerActive(false)}/>
                <p>By continuing to use our site you are agreeing to our cookie policy</p>
                <mya onClick={() => initTerm.openTermModal('Cookie Policy')}>Learn more</mya>
            </div>
            <div className={`${styles['copy']} ${styles['copy_wrapp']} ${styles['open-popup_opened']}`}>
                <div className={`${styles['container-full']} ${styles['text_center']}`}>
                    <div className={styles['copy__txt']}>© {DATE.CURRENT_YEAR} Elenaglove.com All rights reserved</div>
                    <ul className={styles['copy__list-links']}>
                        <li><mya onClick={() => initTerm.openTermModal('Terms and Conditions')}>Terms and Conditions</mya></li>
                        <li><mya  onClick={() => initTerm.openTermModal('Privacy Policy')}>Privacy Policy</mya></li>
                    </ul>
                </div>
            </div>
            <TermModal {...initTerm}/>
        </div>
    )
}