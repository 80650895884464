import React from 'react';
import ComponentLnd20Profile from '../../components/lnd20Profile';
import img from '../../img/lnd20Profile3';
import * as REDIRECT from '../../constants/redirect';

export default () => {
    return (
        <ComponentLnd20Profile
            backgroundImage={img.images['right_bg.jpg']}
            images={img?.images}
            profileName={'Elena'}
            profileDescription={'I am kind, attentive and friendly, and my intentions are always pure. I will never hurt someone intentionally, and it’s very hard for me to face human rudeness.  All humans were created equal, and this order shouldn’t be changed. In my free time, I like enjoying nature, observing wildlife and dreaming.'}
            redirectLink={REDIRECT.SOFIA_LND20P3}
        />
    );
};